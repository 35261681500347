.footer{
  text-align: center;
  background-color:  rgb(2, 29, 87);
  padding-bottom: 50px;
  color: rgb(205, 217, 233);
}
.mail-link, .phone-icon, .github-link{
    margin: 30px;
}
.phone-icon {
    background-color: transparent;
    border: none;
    color: rgb(205, 217, 233);
    padding: 0;
    cursor: pointer;
    margin: 5px;
  }
 .arrow {
  margin: 20px;
 }
 .footer-text{
   padding: 15px;
 }