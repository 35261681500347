.resume-color {
    background-color: rgb(15, 76, 129);
    padding-bottom: 100px;
    /* margin: -10px; */
   
}


.resume, .resume-h1, .resume-h2  {
    color: #222831;
    border:0;
    font:inherit;
    font-size:100%;
    margin:0;
    padding:0;
    vertical-align:baseline;
    font-family: 'Quicksand', sans-serif;
    }
    
    article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {
    display:block;
    }
    
    .clear {clear: both;}
    
    /* p {
      font-size: 1em;
      line-height: 1.4em;
      margin-bottom: 20px;
      color: rgb(54, 50, 50);
    } */
    
    #cv {
      width: 90%;
      max-width: 800px;
      background: white;
      margin: auto;
      padding-bottom: 50px;
    }
    
    .mainDetails {
      padding: 22px 35px 18px;
      border-bottom: 2px solid rgb(242, 107, 91);
      background:rgb(13, 187, 158);
    }
    
    #name h1 {
      font-size: 2.5em;
      font-weight: 700;
      font-family: 'Play', sans-serif; 
      margin-bottom: -6px;
    }
    
    #name h2 {
      font-size: 2em;
      margin-left: 2px;
      font-family: 'Play', sans-serif; 
    }
    
    #mainArea {
      padding: 0 40px;
    }
    
    #headshot {
      width: 15%;
      float: left;
      margin-right: 30px;
    }
    
    #headshot img {
      width: 100%;
      height: auto;
      -webkit-border-radius: 50px;
      border-radius: 50px;
      /* -webkit-box-shadow: 3px 8px 28px -9px rgba(34,40,49,1); */
      /* -moz-box-shadow: 3px 8px 28px -9px rgba(34,40,49,1); */
      /* box-shadow: 3px 8px 28px -9px rgba(34,40,49,1); */
    }
    
    #name {
      float: left;
    }
    
    #contactDetails {
      float: right;
      text-align: left;
    }
    
    #contactDetails ul {
      list-style-type: none;
      font-size: 0.9em;
      margin-top: 2px;
    }
    
    #contactDetails ul li {
      margin-bottom: 3px;
      color: rgb(54, 50, 50);
    }
    
    #contactDetails ul li a, a[href^=tel] {
      color: #444; 
      text-decoration: none;
      -webkit-transition: all .3s ease-in;
      -moz-transition: all .3s ease-in;
      -o-transition: all .3s ease-in;
      -ms-transition: all .3s ease-in;
      transition: all .3s ease-in;
    }
    
    #contactDetails ul li a:hover { 
      color: rgb(242, 107, 91);
    }
    
    .section:first-child {
      border-top: 0;
    }
    
    .section:last-child {
      padding: 20px 0 10px;
    }
    .section {
      border-top: 1px solid rgb(242, 107, 91);;
      padding: 10px 0 0;
    }
    .sectionTitleSkills {
      float: left;
      width: 25%;
    }
    
    .sectionContentSkills {
      font-size: 0.8em;
      float: right;
      width: 72.5%;
    }
    
    .sectionTitle h1, .sectionTitleSkills h1 {
      font-family: 'Play', sans-serif; 
      font-size: 1.5em;
      color:rgb(165, 187, 23);
    }
    
    .sectionContent h2 {
      font-family: 'Play', sans-serif; 
      font-size: 1.5em;
      margin-bottom: -2px;
    }
    
    .subDetails {
      font-size: 0.8em;
      font-style: italic;
      margin-bottom: 3px;
    }
    
    .keySkills {
      list-style-type: none;
      -moz-column-count:3;
      -webkit-column-count:3;
      column-count:3;
      margin-bottom: 20px;
      font-size: 1em;
      color:rgb(54, 50, 50);
      text-align: left;
    }
    
    .keySkills ul li {
      margin-bottom: 3px;
    }
    .work-experience article h2 {
      float: left;
      text-align: left;
      font-size: 1.5em;
    }
    .work-experience article p {
      float: right;
      text-align: right;
    }
    .education article h2 {
      float: left;
      text-align: left;
      font-size: 1.2em;
    }
    .education article p {
      float: right;
      text-align: right;
    }
  
    .resume-projects a {
      color: rgb(128, 163, 206);
      float: left;
      text-align: left;
    }
    .resume-projects p {
      float: right;
      text-align: left;
    }
    .work-experience-title {
      margin-bottom: 10px;
    }
    .work-experience-list, .education-list {
      margin-left: 5%;
      text-align: left;
      font-size: .8em;
    }
    .work-experience-list {
      margin-top: 4%;
    }
    .work-experience-list li {
      margin: 0 0 5px 0;    
    }
    .fa-sm {
      font-size:0.63em;
  }
    @media all and (min-width: 602px) and (max-width: 800px) {
      #headshot {
        display: none;
      }
      
      .keySkills {
      -moz-column-count:2;
      -webkit-column-count:2;
      column-count:2;
      }
    }
    
    @media all and (max-width: 601px) {
      #cv {
        width: 95%;
        margin: auto;
        min-width: 280px;
      }
      
      #headshot {
        display: none;
      }
      
      #name, #contactDetails {
        float: none;
        width: 100%;
        text-align: left;
      }
      
      .sectionTitle, .sectionContent {
        float: none;
        width: 100%;
      }
      
      .sectionTitle {
        /* margin-left: -2px; */
        font-size: 1.25em;
        
      }
      
      .keySkills {
        -moz-column-count:2;
        -webkit-column-count:2;
        column-count:2;
      }
    }
    
    @media all and (max-width: 480px) {
      .mainDetails {
        padding: 15px 15px;
      }
      
      section {
        padding: 15px 0 0;
      }
      
      #mainArea {
        padding: 0 25px;
      }
    
      
      .keySkills {
      -moz-column-count:1;
      -webkit-column-count:1;
      column-count:1;
      }
      
      #name h1 {
        line-height: .8em;
        margin-bottom: 4px;
      }
    }
    
    @media print {
        #cv {
            width: 100%;
        }
    }
    
    @-webkit-keyframes reset {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
    
    @-webkit-keyframes fade-in {
      0% {
        opacity: 0;
      }
      40% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    
    @-moz-keyframes reset {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
    
    @-moz-keyframes fade-in {
      0% {
        opacity: 0;
      }
      40% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    
    @keyframes reset {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
    
    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      40% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    
    .instaFade {
        -webkit-animation-name: reset, fade-in;
        -webkit-animation-duration: 1.5s;
        -webkit-animation-timing-function: ease-in;
      
      -moz-animation-name: reset, fade-in;
        -moz-animation-duration: 1.5s;
        -moz-animation-timing-function: ease-in;
      
      animation-name: reset, fade-in;
        animation-duration: 1.5s;
        animation-timing-function: ease-in;
    }
    
    .quickFade {
        -webkit-animation-name: reset, fade-in;
        -webkit-animation-duration: 2.5s;
        -webkit-animation-timing-function: ease-in;
      
      -moz-animation-name: reset, fade-in;
        -moz-animation-duration: 2.5s;
        -moz-animation-timing-function: ease-in;
      
      animation-name: reset, fade-in;
        animation-duration: 2.5s;
        animation-timing-function: ease-in;
    }
     
    .delayOne {
      -webkit-animation-delay: 0, .5s;
      -moz-animation-delay: 0, .5s;
      animation-delay: 0, .5s;
    }
    
    .delayTwo {
      -webkit-animation-delay: 0, 1s;
      -moz-animation-delay: 0, 1s;
      animation-delay: 0, 1s;
    }
    
    .delayThree {
      -webkit-animation-delay: 0, 1.5s;
      -moz-animation-delay: 0, 1.5s;
      animation-delay: 0, 1.5s;
    }
    
    .delayFour {
      -webkit-animation-delay: 0, 2s;
      -moz-animation-delay: 0, 2s;
      animation-delay: 0, 2s;
    }
    
    .delayFive {
      -webkit-animation-delay: 0, 2.5s;
      -moz-animation-delay: 0, 2.5s;
      animation-delay: 0, 2.5s;
    }
  